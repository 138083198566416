<template>
  <div class="app-container excel">
    <div style="margin-bottom: 10px">
      <el-button
        size="small"
        type="primary"
        @click="$router.push({ name: 'CorUnitList' })"
        >上下游主体查询</el-button
      >
    </div>
    <el-table
      :data="list"
      v-loading="listLoading"
      border
      fit
      style="width: 100%"
    >
      <el-table-column label="全省地市州主体上链登记状态统计表" align="center">
        <el-table-column prop="region_name" align="center"> </el-table-column>
        <el-table-column label="已登记数" prop="ydj" align="center">
        </el-table-column>
        <el-table-column label="未登记数" prop="wdj" align="center">
        </el-table-column>
      </el-table-column>
    </el-table>
  </div>
</template>


<script>
  import axios from "@/util/api";
export default {
  data() {
    return {
      listLoading: false,
      list: [],
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.listLoading = true;
      axios.get("/pc/data-statistics/company-tag-city-list").then((response) => {
        let { data } = response;
        this.list = data;
        this.listLoading = false;
      });
    },
  },
};
</script>

<style>
.row-expand-unhas .el-table__expand-column {
  pointer-events: none;
}
.row-expand-unhas .el-table__expand-column .el-icon {
  visibility: hidden;
}
.excel .el-table__expanded-cell {
  background-color: rgb(217, 236, 255);
}
</style>